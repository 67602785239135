import React, { useMemo } from 'react';
import s from './s.module.less';
import { Collapse } from 'antd';
import { Email } from 'constants/contact';

const HelpCenter = () => {
    const items = useMemo(() => {
        return [
            {
                key: '1',
                label: 'How do I activate my Black Friday discount?',
                children: 'You can activate yourBlack Friday discount by using code”Kiwiblackfriday” at the checkout.',
            },
            {
                key: '2',
                label: 'I have an existing subscription with Kiwi. Do I still get advantage of Black Friday deal?',
                children: 'Yes! We have Black friday offer for new and existing users. If you have an active subscription with Kiwi, your Black friday discount will be automatically applied to your next subscription',
            },
            {
                key: '3',
                label: 'What are the offers under Black Friday?',
                children: 'You get 25% discount on monthly and quarterly plans and 35% discounts on semi-annual and annual plans',
            },
            {
                key: '4',
                label: 'I want to upgrade my plan from essential to growth. Am I eligible for Black Friday Discount?',
                children: 'Yes. We are offering 25% discounts on monthly and quarterly plans and 35% discounts on Semi-Annual and yearly plans. There are valid for both Essential and Growth Plans',
            },
        ];
    }, []);
    return (
        <div className={s.wrap}>
            <div className={s.header}>Help center</div>
            {/* <Collapse items={items} bordered={false} defaultActiveKey={['1', '2', '3', '4']} /> */}
            <Collapse bordered={false} defaultActiveKey={['1', '2', '3', '4']}>
                <Collapse.Panel
                    header={
                        <div className={s.collapseHeader}>
                            How do I activate my Black Friday discount?
                            <div className={`${s.iconBox} ${s.expandIcon}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    }
                    key="1"
                    showArrow={false}
                >
                    <div className={s.text}>
                        <p>You can activate yourBlack Friday discount by using code”Kiwiblackfriday” at the checkout.</p>
                    </div>
                </Collapse.Panel>
                <Collapse.Panel
                    header={
                        <div className={s.collapseHeader}>
                            I have an existing subscription with Kiwi. Do I still get advantage of Black Friday deal?
                            <div className={`${s.iconBox} ${s.expandIcon}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    }
                    key="2"
                    showArrow={false}
                >
                    <div className={s.text}>
                        <p>Yes! We have Black friday offer for new and existing users. If you have an active subscription with Kiwi, your Black friday discount will be automatically applied to your next subscription</p>
                    </div>
                </Collapse.Panel>
                <Collapse.Panel
                    header={
                        <div className={s.collapseHeader}>
                            What are the offers under Black Friday?
                            <div className={`${s.iconBox} ${s.expandIcon}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    }
                    key="3"
                    showArrow={false}
                >
                    <div className={s.text}>
                        <p>You get 25% discount on monthly and quarterly plans and 35% discounts on semi-annual and annual plans</p>
                    </div>
                </Collapse.Panel>
                <Collapse.Panel
                    header={
                        <div className={s.collapseHeader}>
                            I want to upgrade my plan from essential to growth. Am I eligible for Black Friday Discount?
                            <div className={`${s.iconBox} ${s.expandIcon}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    }
                    key="4"
                    showArrow={false}
                >
                    <div className={s.text}>
                        <p>Yes. We are offering 25% discounts on monthly and quarterly plans and 35% discounts on Semi-Annual and yearly plans. There are valid for both Essential and Growth Plans</p>
                    </div>
                </Collapse.Panel>
            </Collapse>

            <div className={s.contact}>
                <div className={s.label}>Contact us:</div>
                <a href={`mailto:${Email.klarity_provider_support}`}>{Email.klarity_provider_support}</a>
            </div>
        </div>
    );
};

export default HelpCenter;
