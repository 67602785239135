import React from 'react';
import { Button } from 'antd';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { openKiwiPlanAdvertisingModal, openManageSubscriptionModal } from 'utils/globalLayerControl';
import { isFreeUserByInfo } from 'utils/provider';
import SubscriptionStore from 'store/Subscription';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { TSubscriptionType } from 'types/subscription';

const InitStep: React.FC<{ className: string; }> = ({
    className,
}) => {
    const navigate = useNavigate();
    const payPlan = SubscriptionStore.get('currentPlan');
    const isFreeUser = payPlan && isFreeUserByInfo(payPlan);
    const isEssentialUser = payPlan && payPlan.type === TSubscriptionType.ESSENTIAL;
    return (
        <div className={className}>
            <div className={s.content}>
                <p>
                    Enter your coupon code if you have one and upgrade to the Growth plan to get advanced features and 40+ channels access, maximizing your reach.
                </p>
            </div>
            <div className={s.buttonWrap}>
                {
                    (isFreeUser || isEssentialUser) &&
                    <Button
                        type="primary"
                        onClick={() => {
                            if (isEssentialUser) {
                                openManageSubscriptionModal();
                                return;
                            }
                            openKiwiPlanAdvertisingModal();
                        }}
                    >
                        Upgrade to Growth plan
                    </Button>
                }
                {
                    !isFreeUser &&
                    <Button
                        type="primary"
                        onClick={() => {
                            openManageSubscriptionModal();
                        }}
                    >
                        Manage your subscription
                    </Button>
                }
            </div>
        </div>
    );
};

export default InitStep;
