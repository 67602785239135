// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--ZXSz7 {
  display: inline-block;
  line-height: 20px;
  border-radius: 20px;
  padding: 0 24px;
  font-size: 12px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/pages/operation/Configurations/components/ReferralConfig/components/Status/s.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".wrap {\n  display: inline-block;\n  line-height: 20px;\n  border-radius: 20px;\n  padding: 0 24px;\n  font-size: 12px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--ZXSz7`
};
export default ___CSS_LOADER_EXPORT___;
