import { Dayjs } from "dayjs";
import { EDiscoutType } from "types/common";

export enum FormFieldEnum {
    name = 'name',
    rules = 'rules',

    duration = 'duration',

    startDateTime = 'startDateTime',
    endDateTime = 'endDateTime',

    referralTitle = 'referralTitle',
    referralMarketingContent = 'referralMarketingContent',

    referrerRewardApplyAllProducts = 'referrerRewardApplyAllProducts',
    referrerRewardDiscountType = 'referrerRewardDiscountType',
    referrerRewardDiscountValue = 'referrerRewardDiscountValue',
    referrerRewardPrice = 'referrerRewardPrice',

    refereeApplyAllProducts = 'refereeApplyAllProducts',
    refereeDiscountType = 'refereeDiscountType',
    refereeDiscountValue = 'refereeDiscountValue',
    refereeRewardPrice = 'refereeRewardPrice',

    emailMsgContent = 'emailMsgContent',

    landingPageTitle = 'landingPageTitle',
    landingPageContent = 'landingPageContent',

}

export interface FormConfigItemModal {
    [FormFieldEnum.name]: string;
    [FormFieldEnum.rules]: string;
    [FormFieldEnum.duration]: [Dayjs, Dayjs | undefined],
    [FormFieldEnum.startDateTime]: Dayjs;
    [FormFieldEnum.endDateTime]: Dayjs;
    [FormFieldEnum.referralTitle]: string;
    [FormFieldEnum.referralMarketingContent]: string;

    [FormFieldEnum.referrerRewardApplyAllProducts]: boolean;
    [FormFieldEnum.referrerRewardDiscountType]: EDiscoutType;
    [FormFieldEnum.referrerRewardDiscountValue]: number;
    [FormFieldEnum.referrerRewardPrice]?: string[];

    [FormFieldEnum.refereeApplyAllProducts]: boolean;
    [FormFieldEnum.refereeDiscountType]: EDiscoutType;
    [FormFieldEnum.refereeDiscountValue]: number;
    [FormFieldEnum.refereeRewardPrice]?: string[];

    [FormFieldEnum.emailMsgContent]: string;
    [FormFieldEnum.landingPageTitle]: string;
    [FormFieldEnum.landingPageContent]: string;
}
