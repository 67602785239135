import React from 'react';
import { openManageSubscriptionModal } from 'utils/globalLayerControl';
import s from './s.module.less';

const Index: React.FC = () => {
    return (
        <div className={s.wrap}>
            <p className={s.description}>
                Have a Headway coupon code? Unlock your exclusive discounts on Growth
                Plan!
            </p>
            <div className={s.applyButton} onClick={() => openManageSubscriptionModal()}>
                Apply coupon code
            </div>
        </div>
    );
};

export default Index;
