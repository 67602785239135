import React from 'react';
import { useNavigate } from 'react-router-dom';
import s from './s.module.css';
import { isOpPortal } from 'utils/common';
import SignPage from 'pages/SignUpPage';
import LoginPage from 'pages/LoginPage';
import { getHasOnceSuccessLogin } from 'utils/localstore';
import ProviderLoginPage from 'pages/ProviderLoginPage';
import { ECampaignConfigStatus } from 'types/common';
type Props = {
    status: ECampaignConfigStatus
}

const Status = (props: Props) => {
    const { status } = props;
    let color = '#065F46';
    let bgColor = '#D1FAE5';
    if (status === ECampaignConfigStatus.SCHEDULED) {
        color = '#1E40AF';
        bgColor = '#DBEAFE';
    }
    if (status === ECampaignConfigStatus.PAUSED) {
        color = '#92400E';
        bgColor = '#FEF3C7';
    }
    if (status === ECampaignConfigStatus.COMPLETE) {
        color = '#1F2937';
        bgColor = '#F3F4F6';
    }
    return (
        <div className={s.wrap} style={{ color, background: bgColor }}>
            {status}
        </div>
    );
};

export default Status;
